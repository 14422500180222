@import '../theme-bootstrap';

.product-grid-wrapper {
  @include content-contain;
  @include breakpoint($medium-up) {
    border-top: solid 1px $color-black;
    border-bottom: solid 1px $color-black;
  }
  .tabbed-block & {
    border-top: none;
    border-bottom: none;
  }
  .ingredient-gallery__quick-view-related-products & {
    border-bottom: none;
  }
}

.product-grid {
  width: 100%;
  //overflow: hidden;
  &__content {
    @include pie-clearfix;
    max-width: $max-width-large;
    margin: 0 auto 1px;
  }
  &--small-cols-2,
  &--small-cols-3 {
    .product-grid__item {
      float: left;
    }
  }
  &--small-cols-2 .product-grid__item {
    width: 50%;
  }
  &--small-cols-3 .product-grid__item {
    width: 33.333%;
  }
  &__item {
    width: 100%;
    border-bottom: solid 1px $color-black;
    .product-grid--carousel & {
      border-bottom: none;
    }
    .product-grid-extra-content & {
      border-bottom: none;
    }
    &--promo {
      border-bottom: none;
      .basic-tout {
        border-bottom: solid 1px $color-black;
        @include breakpoint($medium-up) {
          border-bottom: none;
        }
      }
    }
    &.last {
      border-bottom-width: 0;
    }
    @include breakpoint($medium-up) {
      border-bottom: none;
    }
  }
  @include breakpoint($medium-up) {
    &__item {
      float: left;
      width: 33.333%;
      .product-grid-extra-content & {
        width: 100%;
      }
    }
    &--medium-cols-1 .product-grid__item {
      width: auto;
      float: none;
    }
    &--medium-cols-2 .product-grid__item {
      width: 50%;
    }
    &--medium-cols-3 .product-grid__item {
      width: 33.333%;
    }
    &--medium-cols-4 .product-grid__item {
      width: 25%;
    }
  }
  &__item {
    &--promo {
      .block,
      .content,
      .node {
        height: 100%;
      }
    }
    .product-grid--carousel & {
      @include breakpoint($landscape-up) {
        margin: 0 35px;
      }
    }
  }
  &--add-to-bag {
    .product-brief__cta--shop-now {
      display: none;
    }
    .product-brief__ctas--desktop {
      margin: 0 0 17px 0;
    }
    .product-brief__ctas--mobile {
      display: block;
    }
    .product-brief {
      .product-add-to-bag {
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
    }
  }
  &.product-grid-extra-content {
    position: relative;
    .product-brief__container {
      @include breakpoint($medium-up) {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    .product-brief__container-wrapper {
      @include breakpoint($medium-up) {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50%;
        margin-right: 5%;
        &.left {
          margin-left: 5%;
          right: auto;
          left: 0;
        }
      }
      @include breakpoint($medium-only) {
        width: 40%;
      }
      @include breakpoint($large-up) {
        width: 25%;
      }
    }
  }
}

.product-grid--carousel {
  position: relative;
}

.sticky_tab_gift {
  .tabbed-block__tabs {
    border: none;
    background: $color-light-gray;
    margin: 0 auto;
    &.filter-fixed-top {
      position: fixed;
      top: 60px;
      @include breakpoint($landscape-up) {
        top: 105px;
      }
      @include breakpoint($portrait-down) {
        top: 56px;
      }
      left: 0;
      right: 0;
      z-index: 9;
    }
  }
  .tabbed-block {
    &__header {
      width: 100%;
      float: left;
      padding: 10px 0;
      border-bottom: 2px solid $color-black;
      border-top: 2px solid $color-black;
    }
    &__text {
      color: $color-black;
      font-family: $font--subheading;
      font-size: 24px;
      letter-spacing: 0.02em;
      clear: both;
      text-align: center;
      height: 0;
      width: 100%;
      overflow: hidden;
      &.current {
        height: auto;
        overflow: visible;
      }
    }
  }
  .tabbed-block__images {
    .tabbed-block__content-item {
      float: left;
    }
  }
  .tabbed-block__content-item.current {
    clear: both;
  }
  .tabbed-block__tab {
    color: $color-drak-black;
    padding: 30px 20px;
    font-family: $font--subheading;
    text-transform: none;
    font-size: 24px;
    letter-spacing: 0.02em;
    @include breakpoint($landscape-up) {
      padding: 30px 10px;
    }
    @include breakpoint($large-up) {
      padding: 30px 20px;
    }
    span {
      font-size: 25px;
      font-family: $font--subheading;
      @include breakpoint($landscape-up) {
        font-size: 22px;
      }
      @include breakpoint($large-up) {
        font-size: 25px;
      }
    }
  }
  .tabbed-block__tab.current {
    color: $color-red;
    span {
      border-bottom: 4px solid $color-red;
      padding-bottom: 4px;
    }
  }
  .tabbed-block__content {
    margin-top: 40px;
    .product-grid__item--promo {
      .basic-tout__inner--white {
        @include breakpoint($small-down) {
          min-height: 300px;
        }
      }
    }
  }
  .pc_hidden {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .mobile_hidden {
    @include breakpoint($portrait-down) {
      display: none;
    }
  }
  .mobile_dropdown {
    border-bottom: 2px solid $color-black;
    &.tabbed-block__tabs {
      background: $color-white;
    }
    select {
      + a.selectBox {
        font-size: 25px;
        border: none;
        border-top: 2px solid $color-black;
        .selectBox-arrow {
          background-image: url(/media/export/cms/homepage/nav-arrows.png);
          width: 36px;
          height: 42px;
          -webkit-transform: rotate(270deg);
          -moz-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
          -o-transform: rotate(270deg);
          transform: rotate(270deg);
          background-repeat: no-repeat;
          border-top: none;
          border-bottom: none;
          top: 13%;
          right: 10%;
        }
        .selectBox-label {
          color: $color-red;
          text-align: left;
        }
      }
      + a.selectBox.selectBox-menuShowing {
        .selectBox-arrow {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
          top: 18%;
          right: 12%;
        }
      }
    }
  }
  .fluid-image {
    max-width: 1182px;
    margin: auto;
  }
}

.discover_origin {
  .social-gallery__copy {
    @include breakpoint($large-up) {
      width: 70%;
    }
  }
  .discover_header {
    font-size: 45px;
    font-family: $font--subheading;
    margin: 0;
    letter-spacing: 0.03em;
  }
  .discover_content {
    font-family: $font--heading;
    text-transform: none;
    letter-spacing: 0.03em;
    font-size: 22px;
  }
}

.product-add-all-to-bag {
  &__wrapper {
    max-width: 1280px;
    margin: 15px auto;
    text-align: center;
    margin-bottom: 15px;
    .multi-sku-inventory-message {
      @include font--heading--tertiary;
      font-size: 14px;
      display: none;
      margin-top: -20px;
      margin-bottom: 25px;
    }
  }
}

[data-contains-unshoppable][data-is-shoppable],
[data-is-shoppable][data-contains-unshoppable] {
  .multi-sku-inventory-message {
    display: block;
  }
}

.product-grid__item {
  .mpp-container--has-filters .mixitup-initialized & {
    // initially hide items only if mixitup filters is enabled
    display: none;
  }
}

// This should not be here. It should be in common/_basic-tout.scss
// Somehow commit ffab6497 actually got approved and pushed.
a.basic-tout__inner.basic-tout__inner--white {
  display: block;
}
